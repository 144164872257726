import api from './api'

export async function getContact(slug) {
    const data = await api.get(`${process.env.API_URL}/contact?slug=${slug}`)
        .then(res => {
            return res.data
        })
        .catch(err => {
            console.log(err);
            return []
        })
    return data
}