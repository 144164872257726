import React from 'react'
import { Link } from 'gatsby'
import {getConversion} from '../../api/images'

const ContactInfo = ({ contact }) => {
    const { description, directory, first_name, last_name, media, short_description } = contact

    var name = last_name ? last_name + ', ' + first_name : first_name

    return (
        <>
            <div className='row'>
                {media.length > 0 ?
                    <div className='col-12 col-md-2'>
                        <div className='contact-info-image'>
                            <img src={getConversion(media[0])} alt={media[0].name} />
                        </div>
                    </div>
                    :
                    null
                }
                <div className='col-12 col-md-10'>
                    <Link to={'/directory?slug=' + directory.slug}>
                        <span className='sub-title'>{directory.name}</span>
                    </Link>
                    <h3>{name}</h3>
                    <p>{short_description}</p>
                </div>
            </div>
            <div className='row mt-3'>
                <div className='col-12'>
                    <div className='contact-info-desc'>
                        <ul className='long-desc'>
                            {description ?
                                description.split('\n').map((line) => (
                                    <p>{line}</p>
                                ))
                                : null
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactInfo